import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'

const routes = [
  {
    path: '/',
    name: '登录',
    component: () => import('@/views/login'),
  },
  {
    path: '/registered',
    name: '注册',
    component: () => import('@/views/registered'),
  },
  {
    path: '/forgetPass',
    name: '忘记密码',
    component: () => import('@/views/forgetPass'),
  },
  {
    path: '/changePass',
    name: '修改手机号',
    component: () => import('@/views/changePass'),
  },
  {
    path: '/editPass',
    name: '修改密码',
    component: () => import('@/views/editPass'),
  },
  {
    path: '/home',
    name: '主页',
    component: () => import('@/views/home'),
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        name: '欢迎',
        component: () => import('@/views/welcome'),
      },
      {
        path: '/userManage',
        name: '员工管理',
        redirect: '/userList',
        children: [
          {
            path: '/userList',
            name: '员工列表',
            component: () => import('@/views/userManage/list'),
            children: [],
          },
          {
            path: '/userRole',
            name: '角色权限',
            component: () => import('@/views/userManage/role'),
            children: [],
          },
        ],
      },
      {
        path: '/shopOpenManage',
        name: '开户管理',
        component: () => import('@/views/shopOpenManage'),
      },
      {
        path: '/shopManage',
        name: '商户管理',
        component: () => import('@/views/shopManage'),
      },
      {
        path: '/meal',
        name: '产品管理',
        component: () => import('@/views/meal'),
        children: [
          {
            path: '/meal/add',
            name: '新增产品',
            component: () => import('@/views/meal/add'),
          },
          {
            path: '/meal/detail',
            name: '产品详情',
            component: () => import('@/views/meal/detail'),
          },
        ],
      },
      {
        path: '/goodsManage',
        name: '商品管理',
        component: () => import('@/views/goodsManage'),
      },
      {
        path: '/orderManage',
        name: '订单管理',
        redirect: '/orderSale',
        children: [
          {
            path: '/orderSale',
            name: '销售订单',
            component: () => import('@/views/orderManage/sale'),
            children: [
              {
                path: '/orderSale/detail',
                name: '销售订单详情',
                component: () => import('@/views/orderManage/sale/detail'),
              }
            ],
          },
          {
            path: '/orderJoin',
            name: '加盟订单',
            component: () => import('@/views/orderManage/join'),
            children: [
              {
                path: '/orderJoin/detail',
                name: '加盟订单详情',
                component: () => import('@/views/orderManage/join/detail'),
              }
            ],
          },
          {
            path: '/orderRecharge',
            name: '充值订单',
            component: () => import('@/views/orderManage/recharge'),
            children: [
              {
                path: '/orderRecharge/detail',
                name: '充值订单详情',
                component: () => import('@/views/orderManage/recharge/detail'),
              }
            ],
          },
          {
            path: '/rechargeRefund',
            name: '退款订单',
            component: () => import('@/views/orderManage/rechargeRefund'),
            children: [
              {
                path: '/rechargeRefund/detail',
                name: '退款订单详情',
                component: () => import('@/views/orderManage/rechargeRefund/detail'),
              }
            ],
          },
          {
            path: '/orderWithdraw',
            name: '提现/发放',
            component: () => import('@/views/orderManage/withdraw'),
            children: [
              {
                path: '/orderWithdraw/detail',
                name: '订单详情',
                component: () => import('@/views/orderManage/withdraw/detail'),
              }
            ],
          },
          {
            path: '/orderIncome',
            name: '佣金订单',
            component: () => import('@/views/orderManage/income'),
            children: [
              {
                path: '/orderIncome/detail',
                name: '佣金订单详情',
                component: () => import('@/views/orderManage/income/detail'),
              }
            ],
          },
        ],
      },
      {
        path: '/commission',
        name: '佣金管理',
        component: () => import('@/views/commission'),
      },
      {
        path: '/statistics',
        name: '统计报表',
        component: () => import('@/views/statistics'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

const wtiterPath = ['/', '/registered', '/forgetPass',]

router.beforeEach((to, from, next) => {
  NProgress.start()
  // let token = window.localStorage.getItem('USER_TOKEN_INRE')
  // if (!token && !wtiterPath.includes(to.path)) {
  //   next({ path: '/' })
  //   return
  // }

  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
