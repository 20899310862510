import { defineStore } from 'pinia'

export const useStateStore = defineStore('state', {
  state: () => ({
    //加载 true/false
    loadingGlobal: false,

    //平台名称
    platformName: `全讯物联轻交付管理平台`,
    //平台logo地址
    platformLogo: require('@/assets/icons/qx_logo.png'),

    //鉴权信息
    platformHeadType: 'rcmsPlatform',
    Authorization: JSON.parse(window.localStorage.getItem('USER_AUTHOR_INRE')) || {
      Authorization: `'','','',3,20,''`,
    },

    //未读消息数
    msgnoReadForm: {},
    //消息自动刷新间隔 ms
    msgRefreshTime: 300000,

    //可见菜单id集合
    menuIds: [],

    onOffLineMenu: null,
  }),
  getters: {
    getLoadingGlobal: (state) => {
      return state.loadingGlobal
    },
    getPlatformName: (state) => {
      return state.platformName
    },
    getPlatformLogo: (state) => {
      return state.platformLogo
    },
    getPlatformHeadType: (state) => {
      return state.platformHeadType
    },
    getAuthorization: (state) => {
      return state.Authorization.Authorization
    },
    getMsgnoReadForm: (state) => {
      return state.msgnoReadForm
    },
    getMsgRefreshTime: (state) => {
      return state.msgRefreshTime
    },
    getMenuIds: (state) => {
      return state.menuIds
    },
    getOnOffLineMenu: (state) => {
      return state.onOffLineMenu
    },
  },
  actions: {
    setLoadingGlobal(is) {
      this.loadingGlobal = is
    },
    setLoadingGlobal(name) {
      this.platformName = name
    },
    setPlatformLogo(url) {
      this.platformLogo = url
    },
    setPlatformHeadType(type) {
      this.platformHeadType = type
    },
    setAuthorization(Authorization) {
      this.Authorization = Authorization
    },
    setMsgnoReadForm(form) {
      this.msgnoReadForm = form
    },
    setMsgRefreshTime(time) {
      this.msgRefreshTime = time
    },
    setMenuIds(list) {
      this.menuIds = list
    },
    setOnOffLineMenu(onOffLineMenu) {
      this.onOffLineMenu = onOffLineMenu
    },
  },
})
