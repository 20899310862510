<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
import { useStateStore } from '@/store'
export default {
  name: 'App',
  setup() {
    const store = useStateStore()

    const state = reactive({

    })

    return {
      ...toRefs(state),
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  min-width: 1080px !important;
}
</style>
